export const CLIENT_INVITATION_TABLE_HEADER = [
    {
        text: 'In-x',
        sortable: false,
        value: 'index',
        width: '50px'
    },
    {
        text: 'Created at',
        sortable: true,
        value: 'created_at',
        width: '200px'
    },
    {
        text: 'Client',
        sortable: false,
        value: '',
        width: '500px'
    },
    {
        text: 'Type',
        sortable: false,
        value: '',
        width: '100px'
    },
    {
        text: 'Status',
        sortable: false,
        value: '',
        width: ''
    }
]
