<template>
    <v-data-table
        :headers="tableHeader"
        item-key="id"
        :items="getterClientInvitation"
        :server-items-length="getterTotal"
        :single-select="true"
        :loading="loading"
        hide-default-footer
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        must-sort
        @update:options="$emit('sortTable', $event)"
    >
        <template v-slot:body="{ items }">
            <table-skeleton
                v-if="loading && (items && items.length === 0)"
                :per-page="searchOptions.per_page"
                :column-count="tableHeaderLength"
            />
            <table-loader
                v-if="loading"
            />
            <tbody>
                <template>
                    <!-- {{ items }} -->
                    <tr
                        v-for="(item, index) in items"
                        :key="item.id"
                        class="hovered-row"
                    >
                        <td>
                            <div class="">
                                <p>{{ (searchOptions.per_page * (searchOptions.page - 1)) + index + 1 }}.</p>
                            </div>
                        </td>
                        <!-- created -->
                        <td
                            class="td_dates"
                        >
                            <div class="mb-2 d-flex align-center">
                                <div>{{ $date.dateFromNow(item.created_at) }}</div>
                                <information-tooltip>
                                    <span>{{ $date.shortText(item.created_at) }}</span>
                                </information-tooltip>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-center">
                                <client-avatar
                                    :id="item.client.id"
                                    access-status="client"
                                    :firstname="item.client.firstname"
                                    :lastname="item.client.lastname"
                                    :orders-count="item.client.statistics && item.client.statistics.orders_total"
                                    :client-country="item.client.country"
                                    avatar-size="45"
                                    class="mr-4"
                                />
                                <div class="d-flex flex-column ml-4">
                                    <p
                                        class="mb-0 font-weight-bold text-decoration-none black--text"
                                        style="cursor: pointer"
                                        @click="goToDetails(item.client.id)"
                                    >
                                        {{ nameCapitalized(item.client.firstname) }} {{ nameCapitalized(item.client.lastname) }}
                                    </p>
                                    <span class="grey--text mb-1 d-flex align-center">
                                        {{ item.client.id }}
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td class="">
                            <v-icon
                                class="p"
                                color="primary"
                            >
                                {{ `mdi-${item.type}` }}
                            </v-icon>
                        </td>
                        <td class="">
                            {{ item.status }}
                        </td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex';
import filtersMixin from '@/mixins/filtersMixin'

import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableLoader from '@/components/shared/Tables/TableLoader.vue';

import { nameCapitalized } from '@/helpers/capitalized/index'

import {
    CLIENT_INVITATION_TABLE_HEADER
} from '@/constants/ClientInvitation'
import InformationTooltip from '@/components/shared/InformationTooltip.vue';
import ClientAvatar from '@/components/shared/Avatars/ClientAvatar.vue';

export default {
    name: 'ClientInvitationTable',
    components: {
        ClientAvatar,
        InformationTooltip,
        TableSkeleton,
        TableLoader
    },
    mixins: [
        filtersMixin
    ],
    props: {
        searchOptions: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        sortDesc: {
            type: Boolean,
            required: true
        },
        sortBy: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        ...mapGetters('clientInvitation', [
            'getterClientInvitation',
            'getterTotal'
        ]),
        tableHeader() {
            return CLIENT_INVITATION_TABLE_HEADER
        },
        tableHeaderLength() {
            return CLIENT_INVITATION_TABLE_HEADER.length + 1
        }
    },
    methods: {
        nameCapitalized,
        goToDetails(id) {
            this.$router.push({
                name: 'hr-clients-details',
                params: { id }
            })
        }
    }
}
</script>

<style lang="scss">
.payroll-page .v-data-table__wrapper {
    overflow: visible!important;
}

.domain-batch .domain {
  justify-content: normal;
}

.batch-orders{
    background-color: #E3F2FD;
    border-color: #E3F2FD;
}

.online-status{
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
    box-shadow: 0 0 25px 2px red;
    &--green {
        background: green;
        box-shadow: 0 0 25px 2px green;
    }
}

.cursor-pointer {
    cursor: pointer;
}

</style>
